import React from "react";

import ParticleComponent from "common/src/reusecore/Particle";
import PageHeaderWrapper from "./pageHeader.style";

const PageHeader = ({ categories, title, author, back }) => {
  return (
    <PageHeaderWrapper>
      <ParticleComponent />
      <div className="page-header" 
          style={{backgroundImage: `url(${back})`,boxShadow: "inset 0 0 0 2000px var(--Overlay)",}}>
        <h1 style={{color: "var(--textBlog)"}}
                            dangerouslySetInnerHTML={{ __html: title }}

        ></h1>
        {categories && (
          <div className="breadcrumbs post">
            <h4 style={{color: "var(--textBlog)"}}>En:</h4>
            {categories.map((category, index) => (
              <span key={index}> {category}  </span>
            ))}
            {author && (
              <>
                <h4 style={{color: "var(--textBlog)"}}>Por:</h4> <span>{author.name}</span>
              </>
            )}
          </div>
        )}
        {!categories && title && (
          <div className="breadcrumbs page">
            <>
              <span> Home </span> <span> > </span> {title}
            </>
          </div>
        )}
      </div>
    </PageHeaderWrapper>
  );
};
export default PageHeader;
