import React from 'react';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

const Compartir = ({url, title}) => {
    return (
        <>
        <EmailShareButton
          url={url}
          quote={title}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
        <FacebookShareButton
          url={url}
          quote={title}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton
          url={url}
          quote={title}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <PinterestShareButton
          url={url}
          quote={title}
        >
          <PinterestIcon size={32} round />
        </PinterestShareButton>
        <RedditShareButton
          url={url}
          quote={title}
        >
          <RedditIcon size={32} round />
        </RedditShareButton>
        <TelegramShareButton
          url={url}
          quote={title}
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <TumblrShareButton
          url={url}
          quote={title}
        >
          <TumblrIcon size={32} round />
        </TumblrShareButton>
        <TwitterShareButton
          url={url}
          quote={title}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <WhatsappShareButton
          url={url}
          quote={title}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
            
        </>
    );
};

export default Compartir;