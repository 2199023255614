import React from "react";
import { Link } from "gatsby";
import { FacebookProvider, Comments } from 'react-facebook';

import Compartir from "./Compartir.js"

import { Container, Row, Col } from "common/src/reusecore/Layout";

import PageHeader from "common/src/reusecore/PageHeader";

import BlogPageWrapper from "./blogSingle.style";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-64452838-7');

const BlogSingle = ({data}) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  ReactGA.pageview(url)

  return (
    <BlogPageWrapper key={data.wordpressPost.slug}>
      <PageHeader
        title={data.wordpressPost.title}
        back={data.wordpressPost.featured_media.source_url}
        categories={
          data.wordpressPost.categories.map(post => (
            post.name
          ))
        }
        author={{ name: "Alma", profile: "#" }}
      />
      <div className="single-post-wrapper">
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <Row>
                <Col xs={12}>
                    <div className="post-info-block" style={{flexWrap:"wrap"}}>
                      <div className="tags">
                        <span style={{color: "var(--textBanner)"}}>Categorías:</span>
                        {data.wordpressPost.categories.map(post => (
                          <Link to="#" key={post.slug}>{post.name}</Link>
                        ))}
                      </div>
                      <div className="share" style={{flexWrap:"wrap"}}>
                        <Compartir 
                          url={url}
                          title={data.wordpressPost.title}
                        />
                        <span style={{color: "var(--textBanner)"}}>:Compartir</span>
                      </div>
                    </div>
                  <div className="single-post-block">
                    <div className="post-content-block"
                      dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}
                    >
                      
                    </div>
                    
                    <div className="post-content-block">
                      
                      <FacebookProvider appId="154986975296388">
                        <Comments href={url} />
                      </FacebookProvider>
                    </div>
                    <div className="post-info-block" style={{flexWrap:"wrap"}}>
                      <div className="tags">
                        <span style={{color: "var(--textBanner)"}}>Categorías:</span>
                        {data.wordpressPost.categories.map(post => (
                          <Link to="#" key={post.slug}>{post.name}</Link>
                        ))}
                      </div>
                      <div className="share" style={{flexWrap:"wrap"}}>
                        <Compartir 
                          url={url}
                          title={data.wordpressPost.title}
                        />
                        <span style={{color: "var(--textBanner)"}}>:Compartir</span>
                      </div>
                    </div>

                    
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </BlogPageWrapper>
  );
};

export default BlogSingle;
