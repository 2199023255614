import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"

import Navigation from "common/src/sections-gatsby/Navigation"
import BlogSingle from "common/src/sections-gatsby/blog-single"
import Footer from "common/src/sections-gatsby/Footer-classic"

import Helmet from "react-helmet"

import { GlobalStyle } from "common/src/sections-gatsby/app.style"
import theme from "common/src/theme/blog/themeStyles"

const BlogSinglePage = ({data}) => {

  let StrippedString = data.wordpressPost.excerpt.replace(/(<([^>]+)>)/ig,"");
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return(
      <ThemeProvider theme={theme}>
      <Layout>
        <GlobalStyle />
         <Helmet>
          <title itemProp="name" lang="es">{data.wordpressPost.title}</title>
          <meta name="description" content={StrippedString} />
          
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="" />
          <meta name="twitter:title" content={data.wordpressPost.title} />
          <meta name="twitter:description" content={StrippedString} />  

          <meta property="og:title" content={data.wordpressPost.title} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={StrippedString} /> 
          <meta property="og:image" content={data.wordpressPost.featured_media.source_url} /> 
        </Helmet>
        <Navigation />
        <BlogSingle data={data}  />
        <Footer  data={data}/>
      </Layout>
    </ThemeProvider>
  )
}
export default BlogSinglePage

export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      yoast_head
      content
      excerpt
      slug
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        source_url
      }
      categories {
        name
        slug
      }
    }
    allWordpressPost {
      edges {
        node { 
          wordpress_id
          title
          slug
        }
      }
    }
  }
`